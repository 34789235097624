import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class Overview extends Component {
  render() {
    return (
      <div className='main-container fullscreen' id='overview'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl={5} lg={6} md={7}>
              <div className='text-center'>
                <img
                  alt='bnk.dev'
                  className='mb-1'
                  src='/assets/img/logo.svg'
                  style={{ height: 22 }}
                />
                <p className='lead'>Banking for engineers</p>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center mt-3'>
            <Col className='text-center'>
              <Button href={this.props.services.dashboard}>Dashboard</Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    services: state.default.services,
  }
}

export default withRouter(connect(mapStateToProps)(Overview))
