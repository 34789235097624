import Prism from 'prismjs'
import { Col, Container, Row } from 'react-bootstrap'
import React, { Component } from 'react'

class Security extends Component {
  componentDidMount() {
    Prism.highlightAll()
  }

  render() {
    return (
      <div className='main-container mt-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl={5} lg={6} md={7}>
              <div className='text-center'>
                <img
                  alt='bnk.dev'
                  className='mb-1'
                  src='/assets/img/logo.svg'
                  style={{ height: 22 }}
                />
                <p className='lead'>Banking for engineers</p>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>
                Reports or questions regarding security can be sent to{' '}
                <a href='mailto:security@bnk.dev'>security@bnk.dev</a>.
              </p>
              <h4>Audits</h4>
              <p>
                We're audited annually for our compliance with the Payment Card
                Industry (PCI) Data Security Standards (DSS). We're audited as a
                Level I Service Provider (the highest level). This invovles
                penetration tests, architecture reviews, background checks, and
                policy verification.
              </p>
              <h4>Encryption</h4>
              <p>
                All data are stored with disk-level encryption. Certain data
                (tax ID numbers, for example) are additionally encrypted at the
                application level using AES 256.
              </p>
              <p>HTTPS is enforced on all API requests.</p>
              <h4>GPG key</h4>
              <p>
                Should you wish to encrypt data before sending it to us, our GPG
                public key is below.
              </p>
              <pre>
                <code className='language-shell'>
                  {`-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBF/BUN8BEADD3bZqdbk5RJCdoXJY193KRnXNA2rTBJeOWEMVVPHrug1+ZgHQ
2TZvYSIrN5r+PBgB4HA/tpp56u1ou+ersHQ3/HkoLla0Oj+vmnE3/qe94bA2yIQA
ixT0F5OrUf/D30Xa6Y3kHU7/NwDqjmQFS3RLc5lDZ2R/120y4pUlHN0VG38k85Oj
jlFiw4TdL7AyDipMIg8LXNIMD9D3fy3YUZnx7yFGQrmwSUvoZZTsS1N+sY+lL2al
IyjmyDTx+K9LqCV1XQPB+X21NIlVKYfOdFVJUrb3b97XheprZBzsJT6UFBSGMuD0
RSccNOm5/czb3u330+eycVPDMS943EDOkCDlq9DyNSQkJIH6rPpOi0ZY1ME5AGXZ
7ZfRyiEFiM9f48qKNRrysd8XuoN88TGupYHtK3gzCX3DzVr8GhtSGbULFKudS9YO
jKEQ168u5ag+3ip9mqyhlYI+mLUCiNLZEe13R1ir/OHMkJisU/B3+TQL9/M3vVKn
eg8jTofMn5pZYe08JaXB4Zc17GJiFgvKCE38zmfPnoTii0V1HmLcoHYU8cn811Kl
vcNZZMHJCOHj5mm2DHlSGgpssL2PnyL2U8QZ56JC6tNf13/3bjJfybHwfDfvLuF4
N7NLsIsuWRp4MKFKAIoOMCcCV2thxVewH02r7GxnQn1N0HmF40ECSAkn/QARAQAB
tCNibmsuZGV2IHNlY3VyaXR5IDxzZWN1cml0eUBibmsuZGV2PokCTgQTAQoAOBYh
BLiI9PkPSVTahcn3RWNBOiXtcN/rBQJfwVDfAhsDBQsJCAcDBRUKCQgLBRYCAwEA
Ah4BAheAAAoJEGNBOiXtcN/rgD4P/RcMvNGIxvNRNthKn1+GVKGeQVbjIS1z2Lsn
3iYgdrYPGHlRMyDKuTNDlWFOOP0blb77+7N7sOr5O3iNjRM7BDWXBZpN+vcw3rQO
Doo30TgN8hl+9QeLR04ki8i7Va56rsU7T89xhFi6jYUCBfv1OFxePMWOlaDUWaRT
7fTQAlcbht2sQ6M9f5/HeuasMyOSJciNzTtJKnJU70ubBUGHKxZVtTjIJry5NQJP
9cXhgOwlSMxntSEHmRIknfkQFZhB3fQcZqsN65sDHf0kXdgakCj2Kg2gxc/w2GYz
tEiZuxphDXncaSHZT1DYZwMDUzC6IwMcaHnO5N6KNgpAEUZnFHG3nrZyzgOVAQ/W
pY1PGzpY1ddq/psEvvPObLlH5SMPNWtmIawFnryozmn2m8zT3Wxh49/OLgZgkU5p
uV+VkvtplqAmJEuaIPWqQn6kOaFnp9sKDGfV+IArXxiZecgwk/NnBQl0Dj7p7Vzy
XiMPgVvnoaCfqqB62/VSiWET77X7VCnmj0PYT97naZP95HUufLu0vrtsAhTpxZVR
ck46UJQjMsGy40A2/dxPmEw+HB121WNkGA1WQT0r1bwc0Nvckzg2elTbzLpHNKbg
LBxxEbxfruEMXXLXOyZ/NS8VZYaFOQXfqMrUkSVdyg5wX5B4OD70+iaMjswZbVTW
GWr0HYlKuQINBF/BUN8BEADVK4wmKiD3KldxlCR22rI+C1BD3LmNvMEJ1fT/jYsm
P5sssQwIEV/D5ljC80VloISgbimM3oHUJe+dMjIMcsB+BVRT8Givgw4zzlEkN5xu
cMsIeMYeYWdHQet2J69Uc2NLesRQG4VrU/tSaL64yoChn6ih3uTTQ9qCpA5OeQTQ
IeFwqTqytn9s3KQTsGQloUsKr2lJ+L7BCm4+IiGOciz15247uF0U6c17jcQwtvJD
C1uiRIMQcyAC2isSM+fHUfswSEH7Y+LYM3PTufoy6rBSPQC0ljmRbzl5skjmp/b7
632q1NuGpXo5rCJnR4M04ffkBKoIQLS4LbH2afK/w4/g3q8G6aPxZCqC+eBrTPcO
CvkWl5kPY6YiE6W7IUMdO6GQYMVTQ6nZNdOwJEW2VVoBGfyHzQK9PZcAqrJ9u5Zf
pV+K0m+zWEiaTan9mHH1dTbhLDQd7WvO5jRkP2Qp4x70r6OVPHSZ3SKonG+1KTk9
9xc5vesuKiJnbFTSG7LhVy8Bus+uqOQxezOJkhKirS/+Yzh9WTRBWaVR5FwSMHLD
asY4Cy3x9z+8FsoAQBem4HJIXyMqukWvrp95mxgtZXvvbVthTY7PaNy2zDxxYE7P
/o3BNcHBjNohzlfDksUPqv6qDyhf+m6qUymczA3wCQcbrLtun5Hoqj598Q/zXg+f
nwARAQABiQI2BBgBCgAgFiEEuIj0+Q9JVNqFyfdFY0E6Je1w3+sFAl/BUN8CGwwA
CgkQY0E6Je1w3+uAJg/8CSXDG4WAtdC5a6KFW18vbglJIBt4DWOs4cPGJl3R5NuX
BGd1kt7FgIGfDGaEDaeKRwWShB+dh8bSW7PublgXuh0kEyxVXaKVLici+eiHpCZT
V1RSFo4cJPNPoT1YJXn6gXtIXwgWg5M6O/B9Ceer/8z/HDTtv8tpq6rDuPdSnlQh
GsS7Ogyp6II6N9xC6WxC6ZjoRWMhGXzhd101vVaOLWPSmTiXIp9pgshqMfuxxfdl
y0xsbE/IRGsx4JzCi2nD05sbrw97h/5cPx439AgU6kz0rpWJdbpuprhWl4/MlCBu
p0qfaDPFRdGOn5K3dKvpnjWFkAswKVaxQHIypGgeVYMq6qF9oxaRivK6fhDuzNyf
+OB2gaSsFfON6PyVab/ihkPJTwc2jeo0FedXmNctfDGbperXmTXczx/pmfik2KfS
YSSdFCLhNro1aKrlTMHXpjMdZhghGVen06bpVI8wCnSlY3latYUvsXWBFSfAGaBf
4ujMvklqQNlH6bGY08qkNbNYwBG/9iOphfnlV6rCwpnStHjpBe2M/cW5XZ3t9Gtn
ZHiXB8RYtIwpOlNzHZNpVpkOt083YxVyOxBK6vVvsSw51Vbffl4wACXN35l7BGwK
C17YQQQwlE7fLDwLkS54UWG20Wsrcg/8bGfRnH0cVZLCqP1rujP3r27TplFatQk=
=TTTM
-----END PGP PUBLIC KEY BLOCK-----`}
                </code>
              </pre>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Security
