import { Col, Container, Table, Row } from 'react-bootstrap'
import React, { Component } from 'react'

class ModernTreasury extends Component {
  render() {
    return (
      <div className='main-container mt-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl={5} lg={6} md={7}>
              <div className='text-center'>
                <img
                  alt='bnk.dev'
                  className='mb-1'
                  src='/assets/img/logo.svg'
                  style={{ height: 22 }}
                />
                <p className='lead'>Banking for engineers</p>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <div className='text-center'>
                <img
                  alt='bnk.dev'
                  className='mb-3'
                  src='/assets/img/modern-treasury.svg'
                  style={{ height: 22 }}
                />
              </div>
              <p>
                <a
                  href='https://moderntreasury.com'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Modern Treasury
                </a>{' '}
                allows you to automate and manage the full cycle of money
                movement—from payment and approvals to reconciliation and
                accounting—all from one app and API.
              </p>
              <p>
                With Modern Treasury you can manage your bnk.dev account
                together with all your other bank accounts from one place.
              </p>
              <h4>Pricing</h4>
              <Table>
                <tbody>
                  <tr>
                    <td>Account maintenance</td>
                    <td className='text-right'>Free</td>
                  </tr>
                  <tr>
                    <td>ACH transfers</td>
                    <td className='text-right'>USD 1 per transfer</td>
                  </tr>
                </tbody>
              </Table>
              <p>
                You'll be billed at the start of each month for the previous
                month.
              </p>
              <p>
                Volume discounts are available when you expect to pass 1,000
                transfers per month or maintain a balance of USD 250,000.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default ModernTreasury
