/* istanbul ignore file */
export default () => {
  const commonState = {
    analytics: process.env.NODE_ENV === 'production',
    application: {},
    services: { analytics: '', api: '', dashboard: '' },
    session: { isSignedIn: false },
  }

  switch (process.env.NODE_ENV) {
    case 'development':
      return {
        ...commonState,
        services: {
          api: 'http://api.bank.localhost:30001',
          dashboard: 'http://dashboard.bank.localhost:3000',
        },
      }
    case 'test':
      return {
        ...commonState,
        services: { api: '' },
      }
    case 'production':
      return {
        ...commonState,
        services: {
          analytics: 'https://analytics.bnk.dev',
          api: 'https://api.bnk.dev',
          dashboard: 'https://dashboard.bnk.dev',
        },
      }
    default:
      throw new Error('Environment not known: ' + process.env.NODE_ENV)
  }
}
