import * as actionCreators from '../actions/action-creator'
import { store } from '../utils/create-store'

export default function(resource, params = {}) {
  let action, path, service
  switch (resource) {
    case 'initialState':
      path = '/initial-state'
      action = parsedResponse => {
        store.dispatch(actionCreators.setInitialState(parsedResponse))
      }
      service = store.getState().default.services.api
      break

    /* istanbul ignore next */
    default:
      throw new Error()
  }

  fetch(service + path, {
    method: 'GET',
    credentials: 'include',
  })
    .then(response => {
      switch (response.status) {
        case 200:
          return response.json()
        case 401:
          store.dispatch(actionCreators.setSessionStateToSignedOut())
          break
        default:
          throw new Error()
      }
    })
    .then(parsedResponse => action(parsedResponse))
    .catch(error => {
      /* istanbul ignore next */
      console.log(
        'There has been a problem fetching resource.',
        resource,
        error
      )
    })
}
