import defaultState from '../utils/default-state'

export default function appSettingReducers(state = defaultState(), action) {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return { ...state, ...action.value }
    default:
      return state
  }
}
