import { Col, Container, Row } from 'react-bootstrap'
import React, { Component } from 'react'

class Terms extends Component {
  render() {
    return (
      <div className='main-container mt-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col xl={5} lg={6} md={7}>
              <div className='text-center'>
                <img
                  alt='bnk.dev'
                  className='mb-1'
                  src='/assets/img/logo.svg'
                  style={{ height: 22 }}
                />
                <p className='lead'>Banking for engineers</p>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>
                bnk.dev is not a chartered depository institution. We work with
                Blue Ridge Bank, a federally chartered bank, to provide
                depository and payment services. When you open a bnk.dev account
                you are also opening an account with Blue Ridge Bank. By opening
                an account with Blue Ridge Bank you're agreeing to Blue Ridge
                Bank's account opening agreement.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h3>Blue Ridge Bank account opening agreement</h3>
              <h4>
                1 Important information about procedures for opening a new
                account
              </h4>
              <p>
                To help the government fight the funding of terrorism and money
                laundering activities, federal law requires all financial
                institutions to obtain, verify, and record information that
                identifies each person who opens an account.
              </p>
              <p>
                What this means for you: When you open an account, we will ask
                for your name, address, date of birth, and other information
                that will allow us to identify you. We may also ask to see your
                driver's license or other identifying documents.
              </p>
              <h4>2 Agreement</h4>
              <p>
                This document, along with any other documents we give you
                pertaining to your account(s), is a contract that establishes
                rules which control your account(s) with us. Please read this
                carefully and retain it for future reference. If you sign the
                signature card or open or continue to use the account, you agree
                to these rules. You will receive a separate schedule of rates,
                qualifying balances, and fees if they are not included in this
                document. If you have any questions, please call us.
              </p>
              <p>
                This agreement is subject to applicable federal laws, the laws
                of the state of Virginia and other applicable rules such as the
                operating letters of the Federal Reserve Banks and payment
                processing system rules (except to the extent that this
                agreement can and does vary such rules or laws). The body of
                state and federal law that governs our relationship with you,
                however, is too large and complex to be reproduced here. The
                purpose of this document is to:
              </p>
              <ol>
                <li>summarize some laws that apply to common transactions;</li>
                <li>
                  establish rules to cover transactions or events which the law
                  does not regulate;
                </li>
                <li>
                  establish rules for certain transactions or events which the
                  law regulates but permits variation by agreement; and
                </li>
                <li>
                  give you disclosures of some of our policies to which you may
                  be entitled or in which you may be interested.
                </li>
              </ol>
              <p>
                If any provision of this document is found to be unenforceable
                according to its terms, all remaining provisions will continue
                in full force and effect. We may permit some variations from our
                standard agreement, but we must agree to any variation in
                writing either on the signature card for your account or in some
                other document. Nothing in this document is intended to vary our
                duty to act in good faith and with ordinary care when required
                by law.
              </p>
              <p>
                As used in this document the words 'we', 'our', and 'us' mean
                the financial institution and the words 'you' and 'your' mean
                the account holder(s) and anyone else with the authority to
                deposit, withdraw, or exercise control over the funds in the
                account. However, this agreement does not intend, and the terms
                "you" and "your" should not be interpreted, to expand an
                individual's responsibility for an organization's liability. If
                this account is owned by a corporation, partnership or other
                organization, individual liability is determined by the laws
                generally applicable to that type of organization. The headings
                in this document are for convenience or reference only and will
                not govern the interpretation of the provisions. Unless it would
                be inconsistent to do so, words and phrases used in this
                document should be construed so the singular includes the plural
                and the plural includes the singular.
              </p>
              <h4>3 Liability</h4>
              <p>
                You agree, for yourself (and the person or entity you represent
                if you sign as a representative of another) to the terms of this
                account and the schedule of charges. You authorize us to deduct
                these charges, without notice to you, directly from the account
                balance as accrued. You will pay any additional reasonable
                charges for services you request which are not covered by this
                agreement.
              </p>
              <p>
                Each of you also agrees to be jointly and severally
                (individually) liable for any account shortage resulting from
                charges or overdrafts, whether caused by you or another with
                access to this account. This liability is due immediately, and
                we can deduct any amounts deposited into the account and apply
                those amounts to the shortage. You have no right to defer
                payment of this liability, and you are liable regardless of
                whether you signed the item or benefited from the charge or
                overdraft.
              </p>
              <p>
                You will be liable for our costs as well as for our reasonabale
                attorneys' fees, to the extent permitted by law, whether
                incurred as a result of collection or in any other dispute
                involving your account. This includes, but is not limited to,
                disputes between you and another joint owner; you and an
                authorized signer or similar party; or a third party claiming an
                interest in your account. This also includes any action that you
                or a third party takes regarding the account that causes us, in
                good faith, to seek the advice of an attorney, whether or not we
                become involved in the dispute. All costs and attorneys' fees
                can be deducted from your account when they are incurred,
                without notice to you.
              </p>
              <h4>4 Deposits</h4>
              <p>
                We will give only provisional credit until collection is final
                for any items, other than cash, we accept for deposit (including
                items drawn "on us"). Before settlement of any item becomes
                final, we act only as your agent, regardless of the form of
                endorsement or lack of endorsement on the item and even though
                we provide you provisional credit for the item. We may reverse
                any provisional credit for items that are lost, stolen, or
                returned. Unless prohibited by law, we also reserve the right to
                charge back to your account the amount of any item deposited to
                your account or cashed for you which was initially paid by the
                payor bank and which is later returned to us due to an allegedly
                forged, unauthorized or missing endorsement, claim of
                alteration, encoding error, counterfeit cashier's check or other
                problem which in our judgment justifies reversal of credit. You
                authorize us to attempt to collect previously returned items
                without giving you notice, and in attempting to collect we may
                permit the payor bank to hold an item beyond the midnight
                deadline. Actual credit for deposits of, or payable in, foreign
                currency will be at the exchange rate in effect on final
                collection in U.S. dollars. We are not responsible for
                transactions by mail or outside depository until we actually
                record them. We will treat and record all transactions received
                after our "daily cutoff time" on a business day we are open, or
                received on a day we are not open for business, as if initiated
                on the next business day that we are open. At our option, we may
                take an item for collection rather than for deposit. If we
                accept a third-party check or draft for deposit, we may require
                any third-party endorsers to verify or guarantee their
                endorsements, or endorse in our presence.
              </p>
              <h4>5 Withdrawals</h4>
              <p>
                <b>Generally.</b> Unless clearly indicated otherwise on the
                account records, any of you, acting alone, who signs to open the
                account or has authority to make withdrawals may withdraw or
                transfer all or any part of the account balance at any time.
                Each of you (until we receive written notice to the contrary)
                authorizes each other person who signs or has authority to make
                withdrawals to endorse any item payable to you or your order for
                deposit to this account or any other transaction with us.
              </p>
              <p>
                <b>Postdated checks.</b> A postdated check is one which bears a
                date later than the date on which the check is written. We may
                properly pay and charge your account for a postdated check even
                though payment was made before the date of the check, unless we
                have received written notice of the postdating in time to have a
                reasonable opportunity to act. Because we process checks
                mechanically, your notice will not be effective and we will not
                be liable for failing to honor your notice unless it precisely
                identifies the number, date, amount and payee of the item.
              </p>
              <p>
                <b>Checks and withdrawal rules.</b> If you do not purchase your
                check blanks from us, you must be certain that we approve the
                check blanks you purchase. We may refuse any withdrawal or
                transfer request which you attempt on forms not approved by us
                or by any method we do not specifically permit. We may refuse
                any withdrawal or transfer request which is greater in number
                than the frequency permitted, or which is for an amount greater
                or less than any withdrawal limitations. We will use the date
                the transaction is completed by us (as opposed to the date you
                initiate it) to apply the frequency limitations. In addition, we
                may place limitations on the account until your identity is
                verified.
              </p>
              <p>
                Even if we honor a nonconforming request, we are not required to
                do so later. If you violate the stated transaction limitations
                (if any), in our discretion we may close your account or
                reclassify it as a transaction account. If we reclassify your
                account, your account will be subject to the fees and earnings
                rules of the new account classification.
              </p>
              <p>
                If we are presented with an item drawn against your account that
                would be a 'substitute check', as defined by law, but for an
                error or defect in the item introduced in the substitute check
                creation process, you agree that we may pay such item.
              </p>
              <p>
                <b>Cash withdrawals.</b> We recommend you take care when making
                large cash withdrawals because carrying large amounts of cash
                may pose a danger to your personal safety. As an alternative to
                making a large cash withdrawal, you may want to consider a
                cashier's check or similar instrument. You assume full
                responsibility of any loss in the event the cash you withdraw is
                lost, stolen, or destroyed. You agree to hold us harmless from
                any loss you incur as a result of your decision to withdraw
                funds in the form of cash.
              </p>
              <p>
                <b>
                  Multiple signatures, electronic check conversion, and similar
                  transactions.
                </b>{' '}
                An electronic check conversion transaction is a transaction
                where a check or similar item is converted into an electronic
                fund transfer as defined in the Electronic Fund Transfers
                regulation. In these types of transactions the check or similar
                item is either removed from circulation (truncated) or given
                back to you. As a result, we have no opportunity to review the
                signatures or otherwise examine the original check or item. You
                agree that, as to these or any items as to which we have no
                opportunity to examine the signatures, you waive any requirement
                of multiple signatures.
              </p>
              <p>
                <b>Notice of withdrawal.</b> We reserve the right to require not
                less than 7 days' notice in writing before each withdrawal from
                an interest-bearing account other than a time deposit or demand
                deposit, or from any other savings account as defined by
                Regulation D. (The law requires us to reserve this right, but it
                is not our general policy to use it.) Withdrawals from a time
                account prior to maturity or prior to any notice period may be
                restricted and may be subject to penalty. See your notice of
                penalty for early withdrawal.
              </p>
              <h4>6 Ownership of account and beneficiary designation</h4>
              <p>
                These rules apply to this account depending on the form of
                ownership and beneficiary designation, if any, specified on the
                account records. We make no representations as to the
                appropriateness or effect of the ownership and beneficiary
                designations, except as they determine to whom we pay the
                account funds.
              </p>
              <p>
                <b>Individual account.</b> This is an account in the name of one
                person.
              </p>
              <p>
                <b>Joint account - with survivorship.</b> (And Not As Tenants In
                Common). This is an account in the name of two or more persons.
                Each of you intend and agree that on the death of a party to the
                account, the deceased party's ownership in the account passes to
                the surviving party or parties to the account. This is subject
                to any previous pledge to which we have agreed. If two or more
                of you survive, you will own the balance in the account as joint
                tenants with survivorship and not as tenants in common.
              </p>
              <p>
                <b>Joint account - no survivorship.</b> (As Tenants In common).
                This is owned by two or more persons, but none of you intend
                (merely by opening this account) to create any right of
                survivorship in any other person. Each of you intend that when
                you die your interest in this account will pass as a part of
                your estate under a will, trust, or by intestacy. We encourage
                you to agree and tell us in writing of the percentage of the
                deposit contributed by each of you. This information will not,
                however, affect the number of signatures necessary for
                withdrawal.
              </p>
              <p>
                <b>Revocable trust or pay-on-death account.</b> If two or more
                of you create this type of account, you own the account jointly
                with survivorship. Beneficiaries cannot withdraw unless: (1) all
                persons creating the account die, and (2) the beneficiary is
                then living. If two or more beneficiaries are named and survive
                the death of all persons creating the account, beneficiaries
                will own this account in equal shares, without right of
                survivorship. The person(s) creating either of these account
                types may: (1) change beneficiaries, (2) change account types,
                and (3) withdraw all or part of the account funds at any time.
              </p>
              <h4>7 Business, organization, and association accounts</h4>
              <p>
                Earnings in the form of interest, dividends, or credits will be
                paid only on collected funds, unless otherwise provided by law
                or our policy. You represent that you have the authority to open
                and conduct business on this account on behalf of the entity. We
                may require the governing body of the entity opening the account
                to give us a separate authorization telling us who is authorized
                to act on its behalf. We will honor the authorization until we
                actually receive written notice of a change from the governing
                body of the entity.
              </p>
              <h4>8 Stop payments</h4>
              <p>
                Unless otherwise provided, the rules in this section cover
                stopping payment of items such as checks and drafts. Rules for
                stopping payment of other types of transfers of funds, such as
                consumer electronic fund transfers, may be established by law or
                our policy. If we have not disclosed these rules to you
                elsewhere, you may ask us about those rules.
              </p>
              <p>
                We may accept an order to stop payment on any item from any one
                of you. You must make any stop-payment order in the manner
                required by law and we must receive it in time to give us a
                reasonable opportunity to act on it before our stop-payment
                cutoff time. Because stop-payment orders are handled by
                computers, to be effective, your stop-payment order must
                precisely identify the number, date, and amount of the item, and
                the payee.
              </p>
              <p>
                You may stop payment on any item drawn on your account whether
                you sign the item or not. Generally, if your stop-payment order
                is given to us in writing it is effective for six months. Your
                order will lapse after that time if you do not renew the order
                in writing before the end of the six-month period. If the
                original stop-payment order was oral your stop-payment order
                will lapse after 14 calendar days if you do not confirm your
                order in writing within that time period. We are not obligated
                to notify you when a stop-payment order expires.
              </p>
              <p>
                If you stop payment on an item and we incur any damages or
                expenses because of the stop payment, you agree to indemnify us
                for those damages or expenses, including attorneys' fees. You
                assign to us all rights against the payee or any other holder of
                the item. You agree to cooperate with us in any legal actions
                that we may take against such persons. You should be aware that
                anyone holding the item may be entitled to enforce payment
                against you despite the stop-payment order.
              </p>
              <p>
                Our stop-payment cutoff time is one hour after the opening of
                the next banking day after the banking day on which we receive
                the item. Additional limitations on our obligation to stop
                payment are provided by law (e.g., we paid the item in cash or
                we certified the item).
              </p>
              <h4>9 Telephone transfers</h4>
              <p>
                A telephone transfer of funds from this account to another
                account with us, if otherwise arranged for or permitted, may be
                made by the same persons and under the same conditions generally
                applicable to withdrawals made in writing. Limitations on the
                number of telephonic transfers from a savings account are
                described elsewhere.
              </p>
              <h4>10 Amendments and termination</h4>
              <p>
                We may change any term of this agreement. Rules governing
                changes in interest rates are provided separately in the
                Truth-in-Savings disclosure or in another document. For other
                changes, we will give you reasonable notice in writing or by any
                other method permitted by law. We may also close this account at
                any time upon reasonable notice to you and tender of the account
                balance personally or by mail. Items presented for payment after
                the account is closed may be dishonored. When you close your
                account, you are responsible for leaving enough money in the
                account to cover any outstanding items to be paid from the
                account. Reasonable notice depends on the circumstances, and in
                some cases such as when we cannot verify your identity or we
                suspect fraud, it might be reasonable for us to give you notice
                after the change or account closure becomes effective. For
                instance, if we suspect fraudulent activity with respect to your
                account, we might immediately freeze or close your account and
                then give you notice. If we have notified you of a change in any
                term of your account and you continue to have your account after
                the effective date of the change, you have agreed to the new
                term(s).
              </p>
              <h4>11 Notices</h4>
              <p>
                Any written notice you give us is effective when we actually
                receive it, and it must be given to us according to the specific
                delivery instructions provided elsewhere, if any. We must
                receive it in time to have a reasonable opportunity to act on
                it. If the notice is regarding a check or other item, you must
                give us sufficient information to be able to identify the check
                or item, including the precise check or item number, amount,
                date and payee. Written notice we give you is effective when it
                is deposited in the United States Mail with proper postage and
                addressed to your mailing address we have on file. Notice to any
                of you is notice to all of you.
              </p>
              <h4>12 Statements</h4>
              <p>
                <b>
                  Your duty to report unauthorized signatures, alterations, and
                  forgeries.
                </b>{' '}
                You must examine your statement of account with 'reasonable
                promptness'. If you discover (or reasonably should have
                discovered) any unauthorized signatures or alterations, you must
                promptly notify us of the relevant facts. As between you and us,
                if you fail to do either of these duties, you will have to
                either share the loss with us, or bear the loss entirely
                yourself (depending on whether we used ordinary care and, if
                not, whether we substantially contributed to the loss). The loss
                could be not only with respect to items on the statement but
                other items with unauthorized signatures or alterations by the
                same wrongdoer.
              </p>
              <p>
                You agree that the time you have to examine your statement and
                report to us will depend on the circumstances, but will not, in
                any circumstance, exceed a total of 30 days from when the
                statement is first sent or made available to you.
              </p>
              <p>
                You further agree that if you fail to report any unauthorized
                signatures, alterations or forgeries in your account within 60
                days of when we first send or make the statement available, you
                cannot assert a claim against us on any items in that statement,
                and as between you and us the loss will be entirely yours. This
                60-day limitation is without regard to whether we used ordinary
                care. The limitation in this paragraph is in addition to that
                contained in the first paragraph of this section.
              </p>
              <p>
                <b>Your duty to report other errors or problems.</b> In addition
                to your duty to review your statements for unauthorized
                signatures, alterations and forgeries, you agree to examine your
                statement with reasonable promptness for any other error or
                problem - such as an encoding error or an unexpected deposit
                amount. Also, if you receive or we make available either your
                items or images of your items, you must examine them for any
                unauthorized or missing endorsements or any other problems. You
                agree that the time you have to examine your statement and items
                and report to us will depend on the circumstances. However, this
                time period shall not exceed 60 days. Failure to examine your
                statement and items and report any errors to us within 60 days
                of when we first send or make the statement available precludes
                you from asserting a claim against us for any errors on items
                identified in that statement and as between you and us the loss
                will be entirely yours.
              </p>
              <p>
                <b>
                  Errors relating to electronic fund transfers or substitute
                  checks.
                </b>{' '}
                (For consumer accounts only) . For information on errors
                relating to electronic fund transfers (e.g., on-line, mobile,
                debit card or ATM transactions) refer to your Electronic Fund
                Transfers disclosure and the sections on consumer liability and
                error resolution. For information on errors relating to a
                substitute check you received, refer to your disclosure entitled
                Substitute Checks and Your Rights.
              </p>
              <p>
                <b>Duty to notify if statement not received.</b> You agree to
                immediately notify us if you do not receive your statement by
                the date you normally expect to receive it. Not receiving your
                statement in a timely manner is a sign that there may be an
                issue with your account, such as possible fraud or identity
                theft.
              </p>
              <h4>13 Direct deposits</h4>
              <p>
                If we are required for any reason to reimburse the federal
                government for all or any portion of a benefit payment that was
                directly deposited into your account, you authorize us to deduct
                the amount of our liability to the federal government from the
                account or from any other account you have with us, without
                prior notice and at any time, except as prohibited by law. We
                may also use any other legal remedy to recover the amount of our
                liability.
              </p>
              <h4>14 Temporary account agreement</h4>
              <p>
                If the account documentation indicates that this is a temporary
                account agreement, each person who signs to open the account or
                has authority to make withdrawals (except as indicated to the
                contrary) may transact business on this account. However, we may
                at some time in the future restrict or prohibit further use of
                this account if you fail to comply with the requirements we have
                imposed within a reasonable time.
              </p>
              <h4>15 Setoff</h4>
              <p>
                We may (without prior notice and when permitted by law) set off
                the funds in this account against any due and payable debt any
                of you owe us now or in the future. If this account is owned by
                one or more of you as individuals, we may set off any funds in
                the account against a due and payable debt a partnership owes us
                now or in the future, to the extent of your liability as a
                partner for the partnership debt. If your debt arises from a
                promissory note, then the amount of the due and payable debt
                will be the full amount we have demanded, as entitled under the
                terms of the note, and this amount may include any portion of
                the balance for which we have properly accelerated the due date.
              </p>
              <p>
                This right of setoff does not apply to this account if
                prohibited by law. For example, the right of setoff does not
                apply to this account if: (a) it is an Individual Retirement
                Account or similar tax-deferred account, or (b) the debt is
                created by a consumer credit transaction under a credit card
                plan (but this does not affect our rights under any consensual
                security interest), or (c) the debtor's right of withdrawal only
                arises in a representative capacity. We will not be liable for
                the dishonor of any check when the dishonor occurs because we
                set off a debt against this account. You agree to hold us
                harmless from any claim arising as a result of our exercise of
                our right of setoff.
              </p>
              <h4>16 Check processing</h4>
              <p>
                We process items mechanically by relying solely on the
                information encoded in magnetic ink along the bottom of the
                items. This means that we do not individually examine all of
                your items to determine if the item is properly completed,
                signed and endorsed or to determine if it contains any
                information other than what is encoded in magnetic ink. You
                agree that we have exercised ordinary care if our automated
                processing is consistent with general banking practice, even
                though we do not inspect each item. Because we do not inspect
                each item, if you write a check to multiple payees, we can
                properly pay the check regardless of the number of endorsements
                unless you notify us in writing that the check requires multiple
                endorsements. We must receive the notice in time for us to have
                a reasonable opportunity to act on it, and you must tell us the
                precise date of the check, amount, check number and payee. We
                are not responsible for any unauthorized signature or alteration
                that would not be identified by a reasonable inspection of the
                item. Using an automated process helps us keep costs down for
                you and all account holders.
              </p>
              <h4>17 Check cashing</h4>
              <p>
                We may charge a fee for anyone that does not have an account
                with us who is cashing a check, draft or other instrument
                written on your account. We may also require reasonable
                identification to cash such a check, draft or other instrument.
                We can decide what identification is reasonable under the
                circumstances and such identification may be documentary or
                physical and may include collecting a thumbprint or fingerprint.
              </p>
              <h4>18 Truncation, substitute checks, and other check images</h4>
              <p>
                If you truncate an original check and create a substitute check,
                or other paper or electronic image of the original check, you
                warrant that no one will be asked to make payment on the
                original check, a substitute check or any other electronic or
                paper image, if the payment obligation relating to the original
                check has already been paid. You also warrant that any
                substitute check you create conforms to the legal requirements
                and generally accepted specifications for substitute checks. You
                agree to retain the original check in conformance with our
                internal policy for retaining original checks. You agree to
                indemnify us for any loss we may incur as a result of any
                truncated check transaction you initiate. We can refuse to
                accept substitute checks that have not previously been warranted
                by a bank or other financial institution in conformance with the
                Check 21 Act. Unless specifically stated in a separate agreement
                between you and us, we do not have to accept any other
                electronic or paper image of an original check.
              </p>
              <h4>19 Remotely created checks</h4>
              <p>
                Like any standard check or draft, a remotely created check
                (sometimes called a telecheck, preauthorized draft or demand
                draft) is a check or draft that can be used to withdraw money
                from an account. Unlike a typical check or draft, however, a
                remotely created check is not issued by the paying bank and does
                not contain the signature of the account owner (or a signature
                purported to be the signature of the account owner). In place of
                a signature, the check usually has a statement that the owner
                authorized the check or has the owner's name typed or printed on
                the signature line.
              </p>
              <p>
                You warrant and agree to the following for every remotely
                created check we receive from you for deposit or collection: (1)
                you have received express and verifiable authorization to create
                the check in the amount and to the payee that appears on the
                check; (2) you will maintain proof of the authorization for at
                least 2 years from the date of the authorization, and supply us
                the proof if we ask; and (3) if a check is returned you owe us
                the amount of the check, regardless of when the check is
                returned. We may take funds from your account to pay the amount
                you owe us, and if there are insufficient funds in your account,
                you still owe us the remaining balance.
              </p>
              <h4>20 Unlawful internet gambling notice</h4>
              <p>
                Restricted transactions as defined in Federal Reserve Regulation
                GG are prohibited from being processed through this account or
                relationship. Restricted transactions generally include, but are
                not limited to, those in which credit, electronic fund
                transfers, checks, or drafts are knowingly accepted by gambling
                businesses in connection with the participation by others in
                unlawful Internet gambling.
              </p>
              <h4>21 Guardian or custodial accounts</h4>
              <p>
                This account is not subject to dormant service charges if the
                deposit was made: (1) by a court or (2) by a guardian pursuant
                to order of a court or (3) by any other person for the benefit
                of a person who was an infant at the time of the making of such
                deposit and which deposit is subject to withdrawal only upon the
                further order of such court or such guardian or other person.
                This account may become subject to dormant service charges one
                year after such infant attains the age of eighteen years or one
                year after the death of such infant, whichever occurs sooner. At
                our option, we may require proof of guardian or custodial
                status.
              </p>
              <h4>22 Stale-dated checks</h4>
              <p>
                We are not obligated to, but may at our option, pay a check,
                other than a certified check, presented for payment more than
                six months after its date. If you do not want us to pay a
                stale-dated check, you must place a stop-payment order on the
                check in the manner we have described elsewhere.
              </p>
              <h4>23 ACH and wire transfers</h4>
              <p>
                This agreement is subject to Article 4A of the Uniform
                Commercial Code - Fund Transfers as adopted in the state in
                which you have your account with us. If you originate a fund
                transfer and you identify by name and number a beneficiary
                financial institution, an intermediary financial institution or
                a beneficiary, we and every receiving or beneficiary financial
                institution may rely on the identifying number to make payment.
                We may rely on the number even if it identifies a financial
                institution, person or account other than the one named. You
                agree to be bound by automated clearing house association rules.
                These rules provide, among other things, that payments made to
                you, or originated by you, are provisional until final
                settlement is made through a Federal Reserve Bank or payment is
                otherwise made as provided in Article 4A-403(a) of the Uniform
                Commercial Code. If we do not receive such payment, we are
                entitled to a refund from you in the amount credited to your
                account and the party originating such payment will not be
                considered to have paid the amount so credited. Credit entries
                may be made by ACH. If we receive a payment order to credit an
                account you have with us by wire or ACH, we are not required to
                give you any notice of the payment order or credit.
              </p>
              <h4>24 Facsimile signatures</h4>
              <p>
                Unless you make advance arrangements with us, we have no
                obligation to honor facsimile signatures on your checks or other
                orders. If we do agree to honor items containing facsimile
                signatures, you authorize us, at any time, to charge you for all
                checks, drafts, or other orders, for the payment of money, that
                are drawn on us. You give us this authority regardless of by
                whom or by what means the facsimile signature(s) may have been
                affixed so long as they resemble the facsimile signature
                specimen filed with us, and contain the required number of
                signatures for this purpose. You must notify us at once if you
                suspect that your facsimile signature is being or has been
                misused.
              </p>
              <h4>25 Authorized signer (individual accounts only)</h4>
              <p>
                A single individual is the owner. The authorized signer is
                merely designated to conduct transactions on the owner's behalf.
                The owner does not give up any rights to act on the account, and
                the authorized signer may not in any manner affect the rights of
                the owner or beneficiaries, if any, other than by withdrawing
                funds from the account. The owner is responsible for any
                transactions of the authorized signer. We undertake no
                obligation to monitor transactions to determine that they are on
                the owner's behalf. The owner may terminate the authorization at
                any time, and the authorization is automatically terminated by
                the death of the owner. However, we may continue to honor the
                transactions of the authorized signer until: (a) we have
                received written notice or have actual knowledge of the
                termination of authority, and (b) we have a reasonable
                opportunity to act on that notice or knowledge. We may refuse to
                accept the designation of an authorized signer.
              </p>
              <h4>26 Restrictive legends or endorsements</h4>
              <p>
                The automated processing of the large volume of checks we
                receive prevents us from inspecting or looking for restrictive
                legends, restrictive endorsements or other special instructions
                on every check. For this reason, we are not required to honor
                any restrictive legend or endorsement or other special
                instruction placed on checks you write unless we have agreed in
                writing to the restriction or instruction. Unless we have agreed
                in writing, we are not responsible for any losses, claims,
                damages, or expenses that result from your placement of these
                restrictions or instructions on your checks. Examples of
                restrictive legends placed on checks are 'must be presented
                within 90 days' or 'not valid for more than $1,000.00'. The
                payee's signature accompanied by the words "for deposit only" is
                an example of a restrictive endorsement.
              </p>
              <h4>27 Account transfer</h4>
              <p>
                This account may not be transferred or assigned without our
                prior written consent.
              </p>
              <h4>28 Endorsements</h4>
              <p>
                We may accept for deposit any item payable to you or your order,
                even if they are not endorsed by you. We may give cash back to
                any one of you. We may supply any missing endorsement(s) for any
                item we accept for deposit or collection, and you warrant that
                all endorsements are genuine.
              </p>
              <p>
                To ensure that your check or share draft is processed without
                delay, you must endorse it (sign it on the back) in a specific
                area. Your entire endorsement (whether a signature or a stamp)
                along with any other endorsement information (e.g., additional
                endorsements, ID information, driver's license number, etc.)
                must fall within 1 1/2" of the 'trailing edge' of a check.
                Endorsements must be made in blue or black ink, so that they are
                readable by automated check processing equipment.
              </p>
              <p>
                As you look at the front of a check, the 'trailing edge' is the
                left edge. When you flip the check over, be sure to keep all
                endorsement information within 1 1/2" of that edge.
              </p>
              <p>
                It is important that you confine the endorsement information to
                this area since the remaining blank space will be used by others
                in the processing of the check to place additional needed
                endorsements and information. You agree that you will indemnify,
                defend, and hold us harmless for any loss, liability, damage or
                expense that occurs because your endorsement, another
                endorsement, or information you have printed on the back of the
                check obscures our endorsement. These endorsement guidelines
                apply to both personal and business checks.
              </p>
              <h4>29 Death or incompetence</h4>
              <p>
                You agree to notify us promptly if any person with a right to
                withdraw funds from your account(s) dies or is adjudicated
                (determined by the appropriate official) incompetent. We may
                continue to honor your checks, items, and instructions until:
                (a) we know of your death or adjudication of incompetence, and
                (b) we have had a reasonable opportunity to act on that
                knowledge. You agree that we may pay or certify checks drawn on
                or before the date of death or adjudication of incompetence for
                up to ten (10) days after your death or adjudication of
                incompetence unless ordered to stop payment by someone claiming
                an interest in the account.
              </p>
              <h4>30 Fiduciary accounts</h4>
              <p>
                Accounts may be opened by a person acting in a fiduciary
                capacity. A fiduciary is someone who is appointed to act on
                behalf of and for the benefit of another. We are not responsible
                for the actions of a fiduciary, including the misuse of funds.
                This account may be opened and maintained by a person or persons
                named as a trustee under a written trust agreement, or as
                executors, administrators, or conservators under court orders.
                You understand that by merely opening such an account, we are
                not acting in the capacity of a trustee in connection with the
                trust nor do we undertake any obligation to monitor or enforce
                the terms of the trust or letters.
              </p>
              <h4>31 Credit verification</h4>
              <p>
                You agree that we may verify credit and employment history by
                any necessary means, including preparation of a credit report by
                a credit reporting agency.
              </p>
              <h4>32 Legal actions affecting your account</h4>
              <p>
                If we are served with a subpoena, restraining order, writ of
                attachment or execution, levy, garnishment, search warrant, or
                similar order relating to your account (termed "legal action" in
                this section), we will comply with that legal action. Or, in our
                discretion, we may freeze the assets in the account and not
                allow any payments out of the account until a final court
                determination regarding the legal action. We may do these things
                even if the legal action involves less than all of you. In these
                cases, we will not have any liability to you if there are
                insufficient funds to pay your items because we have withdrawn
                funds from your account or in any way restricted access to your
                funds in accordance with the legal action. Any fees or expenses
                we incur in responding to any legal action (including, without
                limitation, attorneys' fees and our internal expenses) may be
                charged against your account. The list of fees applicable to
                your account(s) provided elsewhere may specify additional fees
                that we may charge for certain legal actions.
              </p>
              <h4>33 Account security</h4>
              <p>
                <b>
                  Duty to protect account information and methods of access.
                </b>{' '}
                It is your responsibility to protect the account numbers and
                electronic access devices (e.g., an ATM card) we provide you for
                your account(s). Do not discuss, compare, or share information
                about your account number(s) with anyone unless you are willing
                to give them full use of your money. An account number can be
                used by thieves to issue an electronic debit or to encode your
                number on a false demand draft which looks like and functions
                like an authorized check. If you furnish your access device and
                grant actual authority to make transfers to another person (a
                family member or coworker, for example) who then exceeds that
                authority, you are liable for the transfers unless we have been
                notified that transfers by that person are no longer authorized.
              </p>
              <p>
                Your account number can also be used to electronically remove
                money from your account, and payment can be made from your
                account even though you did not contact us directly and order
                the payment.
              </p>
              <p>
                You must also take precaution in safeguarding your blank checks.
                Notify us at once if you believe your checks have been lost or
                stolen. As between you and us, if you are negligent in
                safeguarding your checks, you must bear the loss entirely
                yourself or share the loss with us (we may have to share some of
                the loss if we failed to use ordinary care and if we
                substantially contributed to the loss).
              </p>
              <p>
                <b>Positive pay and other fraud prevention services.</b> Except
                for consumer electronic fund transfers subject to Regulation E,
                you agree that if we offer you services appropriate for your
                account to help identify and limit fraud or other unauthorized
                transactions against your account, and you reject those
                services, you will be responsible for any fraudulent or
                unauthorized transactions which could have been prevented by the
                services we offered. You will not be responsible for such
                transactions if we acted in bad faith or to the extent our
                negligence contributed to the loss. Such services include
                positive pay or commercially reasonable security procedures. If
                we offered you a commercially reasonable security procedure
                which you reject, you agree that you are responsible for any
                payment order, whether authorized or not, that we accept in
                compliance with an alternative security procedure that you have
                selected. The positive pay service can help detect and prevent
                check fraud and is appropriate for account holders that issue: a
                high volume of checks, a lot of checks to the general public, or
                checks for large dollar amounts.
              </p>
              <h4>34 Telephonic instructions</h4>
              <p>
                Unless required by law or we have agreed otherwise in writing,
                we are not required to act upon instructions you give us via
                facsimile transmission or leave by voice mail or on a telephone
                answering machine.
              </p>
              <h4>
                35 Monitoring and recording telephone calls and consent to
                receive communications
              </h4>
              <p>
                Subject to federal and state law, we may monitor or record phone
                calls for security reasons, to maintain a record and to ensure
                that you receive courteous and efficient service. You consent in
                advance to any such recording.
              </p>
              <p>
                To provide you with the best possible service in our ongoing
                business relationship for your account we may need to contact
                you about your account from time to time by telephone, text
                messaging or email. However, we first obtain your consent to
                contact you about your account in compliance with applicable
                consumer protection provisions in the federal Telephone Consumer
                Protection Act of 1991 (TCPA), CAN-SPAM Act and their related
                federal regulations and orders issued by the Federal
                Communications Commission (FCC).
              </p>
              <ul>
                <li>
                  Your consent is limited to your account, and as authorized by
                  applicable law and regulations.
                </li>
                <li>
                  Your consent is voluntary and not conditioned on the purchase
                  of any product or service from us.
                </li>
              </ul>
              <p>
                With the above understandings, you authorize us to contact you
                regarding your account throughout its existence using any
                telephone numbers or email addresses that you have previously
                provided to us by virtue of an existing business relationship or
                that you may subsequently provide to us.
              </p>
              <p>
                This consent is regardless of whether the number we use to
                contact you is assigned to a landline, a paging service, a
                cellular wireless service, a specialized mobile radio service,
                other radio common carrier service or any other service for
                which you may be charged for the call. You further authorize us
                to contact you through the use of voice, voice mail and text
                messaging, including the use of pre-recorded or artificial voice
                messages and an automated dialing device. If necessary, you may
                change or remove any of the telephone numbers or email addresses
                at any time using any reasonable means to notify us.
              </p>
              <h4>36 Claim of loss</h4>
              <p>
                If you claim a credit or refund because of a forgery,
                alteration, or any other unauthorized withdrawal, you agree to
                cooperate with us in the investigation of the loss, including
                giving us an affidavit containing whatever reasonable
                information we require concerning your account, the transaction,
                and the circumstances surrounding the loss. You will notify law
                enforcement authorities of any criminal act related to the claim
                of lost, missing, or stolen checks or unauthorized withdrawals.
                We will have a reasonable period of time to investigate the
                facts and circumstances surrounding any claim of loss. Unless we
                have acted in bad faith, we will not be liable for special or
                consequential damages, including loss of profits or opportunity,
                or for attorneys' fees incurred by you. You agree that you will
                not waive any rights you have to recover your loss against
                anyone who is obligated to repay, insure, or otherwise reimburse
                you for your loss. You will pursue your rights or, at our
                option, assign them to us so that we may pursue them. Our
                liability will be reduced by the amount you recover or are
                entitled to recover from these other sources.
              </p>
              <h4>
                37 Early withdrawal penelties (and involuntary withdrawals)
              </h4>
              <p>
                We may impose early withdrawal penalties on a withdrawal from a
                time account even if you don't initiate the withdrawal. For
                instance, the early withdrawal penalty may be imposed if the
                withdrawal is caused by our setoff against funds in the account
                or as a result of an attachment or other legal process. We may
                close your account and impose the early withdrawal penalty on
                the entire account balance in the event of a partial early
                withdrawal. See your notice of penalty for early withdrawals for
                additional information.
              </p>
              <h4>38 Address or name changes</h4>
              <p>
                You are responsible for notifying us of any change in your
                address or your name. Unless we agree otherwise, change of
                address or name must be made in writing by at least one of the
                account holders. Informing us of your address or name change on
                a check reorder form is not sufficient. We will attempt to
                communicate with you only by use of the most recent address you
                have provided to us. If provided elsewhere, we may impose a
                service fee if we attempt to locate you.
              </p>
              <h4>39 Resolving account disputes</h4>
              <p>
                We may place an administrative hold on the funds in your account
                (refuse payment or withdrawal of the funds) if it becomes
                subject to a claim adverse to (1) your own interest; (2) others
                claiming an interest as survivors or beneficiaries of your
                account; or (3) a claim arising by operation of law. The hold
                may be placed for such period of time as we believe reasonably
                necessary to allow a legal proceeding to determine the merits of
                the claim or until we receive evidence satisfactory to us that
                the dispute has been resolved. We will not be liable for any
                items that are dishonored as a consequence of placing a hold on
                funds in your account for these reasons.
              </p>
              <h4>40 Waiver of notices</h4>
              <p>
                To the extent permitted by law, you waive any notice of
                non-payment, dishonor or protest regarding any items credited to
                or charged against your account. For example, if you deposit an
                item and it is returned unpaid or we receive a notice of
                nonpayment, we do not have to notify you unless required by
                federal Regulation CC or other law.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Terms
