import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import '../scss/theme.scss'
import Application from '../containers/application'
import '../utils/analytics'
import { store } from '../utils/create-store'

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Application />
        </BrowserRouter>
      </Provider>
    )
  }
}

export default Root
