import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'

import ModernTreasury from '../components/modern-treasury'
import Overview from '../components/overview'
import Security from '../components/security'
import Terms from '../components/terms'
import fetchResources from '../utils/fetch-resources'

class Application extends Component {
  componentDidMount() {
    fetchResources('initialState')
  }

  render() {
    return (
      <div className='layout layout-nav-side' id='application'>
        <Switch>
          <Route exact path='/' component={Overview} />
          <Route exact path='/modern-treasury' component={ModernTreasury} />
          <Route exact path='/security' component={Security} />
          <Route exact path='/terms' component={Terms} />
          <Redirect to='/' />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Application)
